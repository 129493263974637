import React from "react"
import Layout from "../../components/modules/Layout"
import BodyBlocks from "../../components/technical/BodyBlocks"
import logo from "../../images/ro-holding-logo.svg"

const DemoPage = () => {
  const body = [
    {
      component: "hero_block",
      image: {
        filename: "https://picsum.photos/1920/1080",
      },
      logo: logo,
      kicker: "Der Kicker",
      headline_level: "h1",
      headline: "Die Headline",
      subheadline:
        "Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
      buttons: [
        { link: "/demo", target: "", link_text: "Ein 1. Button" },
        { link: "/demo", target: "", link_text: "Ein 2. Button" },
        { link: "/demo", target: "", link_text: "Ein 3. Button" },
      ],
    },
    // {
    //   component: "sub_nav_block",
    //   sub_nav_items: [
    //     { link: "https://www.google.de", link_text: "Übersicht" },
    //     { link: "https://www.google.de", link_text: "Seminare" },
    //     { link: "https://www.google.de", link_text: "Großveranstaltungen" },
    //     { link: "https://www.google.de", link_text: "Lehrgänge" },
    //     { link: "https://www.google.de", link_text: "Curricula" },
    //     { link: "https://www.google.de", link_text: "Certified-Trainings" },
    //     { link: "https://www.google.de", link_text: "Inhouse-Schulungen" },
    //     { link: "https://www.google.de", link_text: "E-Learning" },
    //     { link: "https://www.google.de", link_text: "Informationen" },
    //     { link: "https://www.google.de", link_text: "Informationen 2" },
    //     { link: "https://www.google.de", link_text: "Weitere Informationen" },
    //   ],
    // },
    {
      component: "downloads_block",
      headline: "Download-Dateien",
      download_items: [
        {
          title: "Test-Bild",
          description:
            "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
          file: {
            filename: "/datei-x.jpeg",
          },
        },
        {
          title: "Test-MP3",
          description:
            "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
          file: {
            filename: "/datei-x.mp3",
          },
        },
      ],
    },
    {
      component: "features_block",
      headline: "Wichtige Features:",
      direction: "vertical",
      feature_items: [
        {
          component: "feature_item",
          icon: {
            type: "fas",
            icon: "fa-info",
          },
          custom_icon: {
            filename: "https://picsum.photos/1080/1080",
          },
          title: "Informationen",
          text: "Ich bin **so glücklich**, _mein Bester_, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
        },
        {
          component: "feature_item",
          icon: {
            type: "fas",
            icon: "fa-info",
          },
          title: "Informationen",
          text: "Ich bin **so glücklich**, _mein Bester_, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
        },
        {
          component: "feature_item",
          icon: {
            type: "fas",
            icon: "fa-info",
          },
          custom_icon: {
            filename: "https://picsum.photos/1080/1080",
          },
          title: "Informationen",
          text: "Ich bin **so glücklich**, _mein Bester_, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
        },
      ],
    },
    {
      component: "article_list_block",
      headline: "Newsübersicht",
      article_items: [
        {
          content:
            '{"isAd":true,"link":"http://www.google.de","image":{"filename":"https://picsum.photos/1920/1080"},"kicker":"Ein Kicker","title":"Die Leiden des jungen Werther","text":"Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet."}',
        },
        {
          content:
            '{"isMember":true,"link":"http://www.google.de","image":{"filename":"https://picsum.photos/1920/1080"},"kicker":"Ein Kicker","title":"Die Leiden des jungen Werther","text":"Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet."}',
        },
      ],
    },
    {
      component: "infobox_block",
      icon: {
        icon: "info",
      },
      kicker: "Ein Kicker",
      headline: "Ein Titel",
      subheadline: "Eine Unterzeile",
      text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
      buttons: [
        { link: "/demo", target: "", link_text: "Ein 1. Button" },
        { link: "/demo", target: "", link_text: "Ein 2. Button" },
      ],
    },
    {
      component: "text_block",
      kicker: "Ein Kicker",
      headline: "Ein Titel",
      subheadline: "Eine Unterzeile",
      text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.\n\n1. Hallo\n2. Welt\n\n* Hallo\n* Welt",
    },
    {
      component: "quote_block",
      image: {
        filename: "https://picsum.photos/1920/1080",
      },
      quote_position: "right",
      quote_style: "bg-lines-gold",
      quote:
        "Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet.",
      author: "Der junge Werther",
      author_description: "Hauptfigur der Geschichte",
    },
    {
      component: "quote_block",
      quote:
        "Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet.",
      author: "Der junge Werther",
      author_description: "Hauptfigur der Geschichte",
    },
    {
      component: "richlink_block",
      title: "Ein Titel",
      description:
        "Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
      link_text: "www.beispiel-seite.de",
      link: "https://www.google.de",
      image: {
        filename: "https://picsum.photos/800/400",
      },
    },
    {
      component: "agenda_block",
      headline: "Agenda",
      tabs: [
        {
          title: "Tag 1",
          subtitle: "Fr. 12. Feb. 2021",
          events: [
            {
              time: "",
              text: "**Eine wunderbare Heiterkeit** hat meine _ganze Seele_ eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
            },
            {
              time: "9:30",
              text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich **den süßen Frühlingsmorgen**, die ich mit ganzem Herzen genieße.",
            },
          ],
        },
        {
          title: "Tag 2",
          subtitle: "Sa., 13. Feb. 2021",
          events: [
            {
              time: "Vormittags",
              text: "Eine _wunderbare_ Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich **mit ganzem Herzen** genieße.",
            },
            {
              time: "Nach der Mittagspause",
              text: "**Eine wunderbare Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
            },
          ],
        },
      ],
    },
    {
      component: "content_nav_block",
      headline: "Navigation",
      nav_items: [
        {
          title: "Glück",
          description:
            "Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet.",
          link: "/",
        },
        {
          title: "Maler",
          description:
            "Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
          link: "/",
        },
        {
          title: "Glück",
          description:
            "Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet.",
          link: "/",
        },
        {
          title: "Maler",
          description:
            "Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
          link: "/",
        },
      ],
    },
  ]

  return (
    <Layout>
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default DemoPage
